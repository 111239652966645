import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import startCase from 'lodash/startCase'
import Dropzone from 'vue2-dropzone'
import { Quill, quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-module'
import Viewer from 'v-viewer/src/component.vue'
import { Prop } from 'vue-property-decorator'
import uniq from 'lodash/uniq'
import join from 'lodash/join'
import Trumbowyg from 'vue-trumbowyg'
import '../../../../node_modules/trumbowyg/dist/plugins/table/trumbowyg.table'

// Base Page
import BasePage from '@/pages/Base'

// Services
import PaymentChannelsService from '@/services/PaymentChannels'
import CountryService from '@/services/Country'

// Configs
import config from '@/configs'

// Global Constant Variables

@Component({ 
  components: { Trumbowyg } 
})
export default class PaymentSettingsIndex extends BasePage {
  constructor() {
    super()
  }

    chooseAutomatic: boolean = false
    selectedPaymentGateway: any = ''

    // Table
    tableLoading: boolean = false
    paymentChannelsList: any = []
    tableLoadingList: boolean = true
    tableTotalItemsList: number | string = 0
    tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
    tablePagination: any = {
      sortBy: 'created_at',
      page: 1,
      rowsPerPage: 10,
      descending: true,
      totalItems: 0
    }

    newChannel: any = {
      name: '',
      gateway: '',
      virtual_account: '',
      instruction: null,
      logo_url: null,
      apikey: ''
    }
    paymentChannelModal: boolean = false

    tableHeaders: object[] = [
      // {
      //     text: 'ID',
      //     align: 'left',
      //     sortable: false,
      //     value: '-id'
      // },
      {
        text: 'Channel Name',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Payment Type',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Action',
        align: 'center',
        sortable: false,
        value: 'action',
        class: 'action-table-width'
      }
    ]

    // itemPaymentType: any = ['Manual', 'API XENDIT (PT. Matahari Labs)', 'API XENDIT (PT. Lintas Mitra Perkasa)']
    itemPaymentType: any = [
      {
        text: 'Manual',
        value: 'manual'
      },
      // {
      //     text: 'API XENDIT (PT. Matahari Labs)',
      //     value: 'xendit_matahari_labs',
      // },
      {
        text: 'API XENDIT (PT. Lintas Mitra Perkasa)',
        value: 'xendit_lintas_mitra_perkasa'
      }

    ]
    selectedPaymentChannel: any = ''
    
    // itemXenditVA: any = ['BRI', 'BNI', 'OVO']
    itemXenditVA: any = [
      // {
      //     text: 'BCA',
      //     value: 'BCA'
      // },
      {
        text: 'BRI',
        value: 'BRI'
      },
      {
        text: 'Mandiri',
        value: 'MANDIRI'
      },
      {
        text: 'BNI',
        value: 'BNI'
      },
      {
        text: 'Permata',
        value: 'PERMATA'
      },
      {
        text: 'Credit Card',
        value: 'CREDIT_CARD'
      },
      {
        text: 'OVO',
        value: 'OVO'
      }
    ]
    selectedXenditVA: any = ''
    useAsEdit: boolean = false
    selectedIdUpdatePaymentChannel: any = null

    // Description
    content: string = ''
    editorOptions: object = {
      modules: {
        imageResize: {
          modules: ['Resize', 'DisplaySize', 'Toolbar']
        },
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          // [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ font: [] }, { color: [] }, { background: [] }],
          [{ direction: 'rtl' }],
          [{ align: [] }],
          ['link', 'image', 'video'],
          ['clean']
        ]
      },
      placeholder: 'Add product description here...',
      theme: 'snow'
    }

    config: object = {
      advanced: {
        autogrow: true,
        removeformatPasted: true,
        // btnsDef: {
        //   save: {
        //     fn: () => {
        //       this.onUpdateDescription();
        //     },
        //     text: "Save Changes",
        //     hasIcon: false,
        //     class: "custom-save-btn"
        //   }
        // },
        // btnsAdd: ["save"],
        btns: [
          ['viewHTML'],
          ['undo', 'redo'],
          ['formatting'],
          ['strong', 'em', 'del'],
          // ["link"],
          // ["insertImage"],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
          ['fullscreen'],
          ['tableAddRow'],
          ['tableAddColumn'],
          ['tableDeleteRow'],
          ['tableDeleteColumn'],
          ['table'],
          ['save']
        ]
      }
    }

    // Delete Payment Channel
    showDeleteModal: boolean = false
    selectedIdDeletePaymentChannel: any = null
    selectedNameDeletePaymentChannel: any = null
    dataCountry: any = []
    dataCountryString: string = ''

    mounted() {
      this.getPaymentChannelsList()
    }

    @Watch('tablePagination', { deep: true })
    async onChanged() {
      this.$vuetify.goTo(0)
      await this.getPaymentChannelsList()
    }

    onPaymentChannelModal() {
      this.paymentChannelModal = true
    }

    closePaymentChannelModal() {
      this.newChannel.name = ''
      this.newChannel.gateway = ''
      this.newChannel.virtual_account = ''
      // this.newChannel.instruction = null
      this.content = ''
      this.newChannel.logo_url = ''
      this.newChannel.apikey = ''
      this.$validator.errors.clear()
      this.$validator.reset()
      this.useAsEdit = false
      this.editBankLogo = false

      this.paymentChannelModal = false
    }

    editBankLogo: boolean = false

    onButtonEditBankLogo() {
      this.editBankLogo = true
    }

    async getPaymentChannelsList () {
      try {
        this.tableLoading = true

        this.paymentChannelsList = []
        const opts: any = {
          params: {
            'page[num]': this.tablePagination.page,
            'page[limit]': this.tablePagination.rowsPerPage
          }
        }
        const response = await PaymentChannelsService.getPaymentChannels(opts)
        const responseMeta = response.data.meta
        const responseData = response.data.data
        this.tableTotalItemsList = responseMeta.pagination.total
        this.paymentChannelsList = []
        for (const dataPaymentChannels of responseData) {
          this.paymentChannelsList.push(dataPaymentChannels.attributes)
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.tableLoadingList = false
      }
    }
    
    async saveNewPaymentChannel () {
      try {
        const valid = await this.$validator.validateAll('newChannel')
            
        if (valid) { 
          this.showLoading({text: 'Saving...'})
          if(this.content) {
            this.newChannel.instruction = this.content
          }
                
          if(this.newChannel.gateway.value === 'xendit_lintas_mitra_perkasa') {
            this.newChannel.apikey = 'xnd_production_PYmBfL4h3LGqkJZrL7pJGzSZNdOrotN/lHDo+Rxg/2DQ/rWpBAB/gA=='
          }

          let data = new FormData()
          data.append('name', this.newChannel.name)
          data.append('instruction', this.newChannel.instruction)
          data.append('gateway', this.newChannel.gateway.text)
          data.append('apikey', this.newChannel.apikey)
          data.append('virtual_account', this.newChannel.virtual_account)
          data.append('logo_url', this.newChannel.logo_url)

          this.showLoading({ text: 'Saving...' })
          const response = await PaymentChannelsService.saveNewPaymentChannel(data)
          console.log(response)

          if (response.data.success) {
            this.showSnackbar({
              text: 'Payment Channel Successfully Added',
              color: 'green',
              timeout: 1500
            })
            this.closeLoading()
            this.closePaymentChannelModal()
            this.paymentChannelModal = false
            this.paymentChannelsList = []
            this.getPaymentChannelsList()
          } else {
            this.showSnackbar({
              text: 'Saved Failed',
              color: 'red',
              timeout: 1500
            })
          }
        } else {
          this.showSnackbar({
            text: 'Please check all fields requirements',
            color: 'red',
            timeout: 2000
          })
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
            
      }
    }

    editPaymentChannel(props) {
      this.selectedIdUpdatePaymentChannel = props.id
      this.newChannel = { 
        name: props.name,
        gateway: null,
        virtual_account: props.virtual_account,
        logo_url: props.logo_url,
        apikey: props.apikey
      }

      // Instruction
      this.content = props.instruction

      // Gateway
      if(props.gateway === 'Manual') {
        this.newChannel.gateway = {
          text: 'Manual',
          value: 'manual'
        }
      } else if (props.gateway === 'API XENDIT (PT. Lintas Mitra Perkasa)') {
        this.newChannel.gateway = {
          text: 'API XENDIT (PT. Lintas Mitra Perkasa)',
          value: 'xendit_lintas_mitra_perkasa'
        }
      }

      this.useAsEdit = true
      this.paymentChannelModal = true
    }

    async updatePaymentChannel() {
      try {
        const valid = await this.$validator.validateAll('newChannel')
            
        if (valid) { 
          this.showLoading({text: 'Saving...'})
          if(this.content) {
            this.newChannel.instruction = this.content
          }
                
          if(this.newChannel.gateway.value === 'xendit_lintas_mitra_perkasa') {
            this.newChannel.apikey = 'xnd_production_PYmBfL4h3LGqkJZrL7pJGzSZNdOrotN/lHDo+Rxg/2DQ/rWpBAB/gA=='
          } else {
            this.newChannel.apikey = null
          }

          let dataEdit = new FormData()
          dataEdit.append('name', this.newChannel.name)
          dataEdit.append('instruction', this.newChannel.instruction)
          dataEdit.append('gateway', this.newChannel.gateway.text)
          dataEdit.append('apikey', this.newChannel.apikey)
          dataEdit.append('virtual_account', this.newChannel.virtual_account)
          dataEdit.append('logo_url', this.newChannel.logo_url)

          this.showLoading({ text: 'Saving...' })

          const response = await PaymentChannelsService.updatePaymentChannel(this.selectedIdUpdatePaymentChannel, dataEdit)

          if (response.data.success) {
            this.showSnackbar({
              text: 'Payment Channel Successfully Updated',
              color: 'green',
              timeout: 1500
            })
            this.closeLoading()
            this.closePaymentChannelModal()
            this.paymentChannelModal = false
            this.paymentChannelsList = []
            this.selectedIdUpdatePaymentChannel = null
            this.getPaymentChannelsList()
          } else {
            this.showSnackbar({
              text: 'Saved Failed',
              color: 'red',
              timeout: 1500
            })
          }
        } else {
          this.showSnackbar({
            text: 'Please check all fields requirements',
            color: 'red',
            timeout: 2000
          })
        }
            
      } catch (error) {
        this.catchHandler(error)
      } finally {
            
      }
    }

    changeLogo(e) {
      const file = e.target.files[0]
      this.newChannel.logo_url = file
    }   
    
    async openDeleteModal(props) {
      const opts: any = {
        params: {
          'filter[payment_channel_id][is]': props.item.id
        }
      }
      const response = await PaymentChannelsService.getCountryPayments(opts)
      const responseData = response.data.data

      if(response.data.data.length > 0) {
        const dataIdCountry = []
        forEach(responseData, data => {
          dataIdCountry.push(data.attributes.country_id)
        })
        const uniqIdCountry = uniq(dataIdCountry)

        // Get Data Country
        const optsCountry: any = {
          params: {
            'filter[id][in]': join(uniqIdCountry, ',')
          }
        }

        this.dataCountry = []
        const responseCountry = await CountryService.getCountries(optsCountry)
        forEach(responseCountry.data.data, country => {
          this.dataCountry.push(country.attributes.name)
        })
        this.dataCountryString = this.dataCountry.toString()
      }
      this.selectedIdDeletePaymentChannel = props.item.id
      this.selectedNameDeletePaymentChannel = props.item.name
      this.showDeleteModal = true
    }
    
    async deletePaymentChannel() {
      try {
			
        this.showDeleteModal = false
        this.showLoading({text: 'Saving...'})
        const response = await PaymentChannelsService.deletePaymentChannel(this.selectedIdDeletePaymentChannel)

        if (response) {
          this.showSnackbar({
            text: 'Delete Successfully',
            color: 'green',
            timeout: 1500
          })
          this.closeLoading()
          this.selectedIdDeletePaymentChannel = null
          this.selectedNameDeletePaymentChannel = null
          this.paymentChannelsList = []
          this.getPaymentChannelsList()
        } else {
          this.showSnackbar({
            text: 'Change Status Failed',
            color: 'red',
            timeout: 1500
          })
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    }
}